// import { STORED_AUTH_TOKEN_KEY } from '../auth';
import env from '../env';
// import { safeLocalStorage } from '../storage';
import { API } from './model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any;

const API_ROOT = env('VITE_API_ROOT');

// function getAuthHeaders() {
//     const tokenFromStorage = safeLocalStorage.get(STORED_AUTH_TOKEN_KEY);
//     if (tokenFromStorage !== undefined) {
//         return { authorization: `Bearer ${tokenFromStorage}` };
//     } else {
//         return {} as {}; // Weird, right?
//     }
// }

/** @deprecated Use the shared `api` function. */
export async function api<
    Path extends keyof API,
    Method extends keyof API[Path],
    Description extends API[Path][Method] extends AnyFunction ? API[Path][Method] : never,
    PayloadShape extends Parameters<Description>[0],
  >(
    method: Method & string,
    path: Path & string,
    // Sorry this is a little hairy, see https://stackoverflow.com/a/52318137 for details.
    ...payloadAndHeaders: [
        ...possiblePayload: (PayloadShape extends undefined ? [noPayload?: PayloadShape] : [payload: PayloadShape]),
        headers?: Record<string, string>,
    ]
) {
    const [payload, headers] = payloadAndHeaders;

    const fallbackOrigin = location.origin; // Used when API_ROOT doesn't include an origin.
    const url = new URL(`${API_ROOT}${path}`, fallbackOrigin);

    if (method === 'GET' && typeof payload === 'object' && payload !== null) {
        Object.entries(payload).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(v => url.searchParams.append(key, v));
            } else {
                url.searchParams.set(key, String(value));
            }
        });
    }

    const sendingFormData = payload instanceof FormData;

    const autoTypeHeader = sendingFormData ? {} as object : {
        'content-type': 'application/json',
    };

    const response = await fetch(url, {
        method,
        body: method === 'GET' ? undefined : sendingFormData ? payload : JSON.stringify(payload),
        credentials: 'same-origin',
        headers: {
            ...autoTypeHeader,
            // ...getAuthHeaders(),
            ...(headers ?? {}),
        },
    });

    type ResponseShapes = ReturnType<Description>;
    type ReturnShapes = {
        [Status in keyof ResponseShapes]: {
            // rawResponse: Response;
            status: Status;
            response: ResponseShapes[Status];
        }
    };
    type ReturnShape = ReturnShapes[keyof ResponseShapes];

    const isJson = response.headers.get('content-type')?.includes('application/json');

    return {
        // rawResponse: response,
        status: response.status,
        response: await response[isJson ? 'json' : 'text'](),
    } as ReturnShape;
}
