import useExternalStyle from 'isc-shared/lib/external-style';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNiceNav } from '../lib/routing';
import workflows, { WorkflowConfig, defaultWorkflow } from '../workflows';

export type Workflow<Values extends object = object> = WorkflowConfig<Values> & {
    key: keyof typeof workflows;
    urlForStep: (step: string, query?: object) => URL;
};

const WorkflowContext = createContext<Workflow | null>(null);

export default function WorkflowProvider(props: Omit<React.ComponentProps<typeof WorkflowContext.Provider>, 'value'>) {
    const params = useParams();
    const [navigate, resolve] = useNiceNav();

    const [workflow, setWorkflow] = useState<React.ContextType<typeof WorkflowContext>>();

    useEffect(() => {
        if (!params.workflow) {
            console.info(`No workflow specified, defaulting to "${defaultWorkflow}"`);
            const search = Object.fromEntries(new URLSearchParams(location.search));
            navigate('step', { workflow: defaultWorkflow }, { search, replace: true });
            return;
        }

        const workflowKey = (params.workflow ?? '') as keyof typeof workflows;
        const workflowGetter = workflows[workflowKey];
        if (!workflowGetter) throw new Error(`Workflow "${workflowKey}" not found`);

        function urlForStep(step: string, query: object = {}) {
            const path = resolve('step', { step });
            const url = new URL(location.href);
            url.pathname = path;
            Object.entries(query).forEach(([key, value]) => url.searchParams.set(key, String(value)));
            return url;
        }

        workflowGetter().then(module => {
            setWorkflow({ ...module.default, key: workflowKey, urlForStep });
        });
    }, [navigate, params.workflow, resolve]);

    useExternalStyle(workflow?.rootClassName);

    if (!workflow) return null;

    return (
        <WorkflowContext.Provider {...props} value={workflow}>
            {props.children}
        </WorkflowContext.Provider>
    );
}

WorkflowProvider.useContext = () => useContext(WorkflowContext);
