import { useCallback, useEffect, useRef } from 'react';
import { Location, NavigateOptions, RouteObject, createBrowserRouter, generatePath, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SubmissionForm from '../components/SubmissionForm';
import MainLayout from '../layouts/Main';

const PERSISTENT_QUERY_PARAMS = ['location', 'region'];

export const routes: RouteObject[] = [{
    id: 'step',
    path: '/:workflow?/:step?',
    element: (
        <MainLayout>
            <SubmissionForm />
        </MainLayout>
    ),
}];

export const router = createBrowserRouter([...routes]);

export function useLocationChange(callback: (location: Location) => void) {
    const location = useLocation();
    useEffect(() => {
        return callback(location);
    }, [callback, location]);
}

export function useNiceNav() {
    const params = useParams();
    const paramsRef = useRef(params); // This has to be non-reactive; TODO: Why?
    paramsRef.current = params;
    const [search] = useSearchParams();
    const navigate = useNavigate();

    const niceResolve = useCallback((
        id: typeof routes[number]['id'],
        newParams: Record<string, string>,
    ) => {
        const route = routes.find(r => r.id === id);
        if (!route) throw new Error(`Route "${id}" not found`);
        if (!route.path) throw new Error(`Route "${id}" has no path`);
        const allParams = { ...paramsRef.current, ...newParams };
        const path = generatePath(route.path, allParams);
        const url = new URL(path, location.href);
        for (const key of PERSISTENT_QUERY_PARAMS) {
            const value = search.get(key);
            if (value) url.searchParams.set(key, value);
        }
        return url.toString().slice(url.origin.length);
    }, [search]);

    const niceNavigate = useCallback((
        id: typeof routes[number]['id'],
        params: Record<string, string>,
        searchAndOptions: { search?: Record<string, string> } & NavigateOptions = {},
    ) => {
        const { search, ...options } = searchAndOptions;

        const newPath = niceResolve(id, params);
        const newUrl = new URL(newPath, location.href);

        for (const [key, value] of Object.entries(search ?? {})) {
            newUrl.searchParams.set(key, value);
        }

        return navigate({
            pathname: newUrl.pathname,
            search: newUrl.searchParams.toString(),
        }, options);
    }, [navigate, niceResolve]);

    return [niceNavigate, niceResolve] as const;
}
