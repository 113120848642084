import { WorkflowSubmission, WorkflowSubmissionData } from '../context/Submission';
import { Workflow } from '../context/Workflow';
import { PostCreationPayload } from '../lib/api/model';
import { IndividualTranslation } from '../lib/i18n';

type MaybePromise<T> = Promise<T> | T;

export interface WorkflowConfig<Values extends object = object> {
    rootClassName?: string;
    pageTitle: IndividualTranslation<string>;
    logo?: () => React.ReactNode;
    firstStep: keyof WorkflowConfig['stepProgression'];
    stepProgression: Record<string, WorkflowStep<Partial<Values>>>;
    collatePayload: (submission: WorkflowSubmissionData<Partial<Values>>) => Promise<PostCreationPayload>;
}

export interface WorkflowStep<Values extends object = object, Component extends React.ComponentType = React.ComponentType> {
    pageTitle?: IndividualTranslation<string>;
    breadcrumbLabel?: IndividualTranslation;
    hideBreadcrumb?: boolean;
    component: StepComponent<Component>;
    getProps?: (submission: WorkflowSubmission<Values>, workflow: Workflow<Values>, step: WorkflowStep<Values, Component>) => Partial<React.ComponentProps<Component>>;
    sendSubmission?: (submission: WorkflowSubmission<Values>) => boolean;
    beforeExit?: (submission: WorkflowSubmission<Values>) => MaybePromise<{ errors: string[] } | void>;
    canSkip?: boolean;
    canProgress?: (submission: WorkflowSubmission<Values>) => boolean;
    next?: (submission: WorkflowSubmission<Values>) => keyof WorkflowConfig['stepProgression'] | null;
}

type StepComponent<Component extends React.ComponentType> = React.ComponentType<React.ComponentProps<Component> & {
    submissionInProgress?: boolean;
    errors?: string[];
    dismissErrors?: (errors: string[]) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (value: any) => void;
}> & {
    defaultPageTitle?: IndividualTranslation<string>;
    defaultBreadcrumbLabel: IndividualTranslation<string>;
};

const workflows = {
    flooding: () => import('./flooding'),
    heat: () => import('./heat'),
} as const;

export const defaultWorkflow = 'flooding';

export function isValidWorkflow(key: unknown): key is keyof typeof workflows {
    return String(key) in workflows;
}

export default workflows;
