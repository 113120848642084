import useExternalStyle from 'isc-shared/lib/external-style';
import typography from 'isc-shared/ui/typography.module.css';
import { useCallback, useEffect } from 'react';
import { Location } from 'react-router-dom';
import QueriedLocationProvider from '../context/QueriedLocation';
import CurrentStepProvider from '../context/Step';
import SubmissionProvider from '../context/Submission';
import WorkflowProvider from '../context/Workflow';
import { useLocationChange } from '../lib/routing';
import trackEvent from '../lib/tracking';
import { useVisualViewportHeight } from '../lib/viewport';
import styles from './main-layout.module.css';

const html = document.documentElement;

export default function MainLayout({ children }: { children: React.ReactNode }) {
    useExternalStyle(styles.html);

    useEffect(() => {
        document.body.classList.add(
            String(typography['baseBody']),
            String(typography['small']),
            String(typography['medium']),
        );
    }, []);

    useVisualViewportHeight((height, scroll) => {
        const cssProperty = '--visual-viewport-height';
        html.style.setProperty(cssProperty, `${height}px`);
        html.scrollTo(html.scrollLeft, scroll);
        return () => {
            html.style.removeProperty(cssProperty);
        };
    });

    // TODO: Is there somewhere above the MainLayout we can establish context?

    const handleLocationChange = useCallback((location: Location) => {
        trackEvent('page_change', { path: location.pathname });
    }, []);

    useLocationChange(handleLocationChange);

    return (
        <QueriedLocationProvider>
            <WorkflowProvider>
                <SubmissionProvider>
                    <CurrentStepProvider>
                        {children}
                    </CurrentStepProvider>
                </SubmissionProvider>
            </WorkflowProvider>
        </QueriedLocationProvider>
    );
}
