import { createContext, useContext, useRef, useState } from 'react';

// Provide access to parent forms from inside a portal.
const FormContext = createContext<HTMLFormElement | null>(null);

export default function Form({
    children,
    ignoreMultipleSubmissions = true,
    ...props
}: {
    ignoreMultipleSubmissions?: boolean;
} & React.ComponentProps<'form'>) {
    const formRef = useRef<HTMLFormElement>(null);
    const [inProgress, setInProgress] = useState(false);

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.stopPropagation();

        if (ignoreMultipleSubmissions && inProgress) return;

        try {
            setInProgress(true);
            event.preventDefault();
            await props.onSubmit?.(event);
        } finally {
            setInProgress(false);
        }
    }

    return (
        <form ref={formRef} {...props} onSubmit={handleSubmit}>
            <FormContext.Provider value={formRef.current}>
                {children}
            </FormContext.Provider>
        </form>
    );
}

Form.useForm = function() {
    return useContext(FormContext);
}
