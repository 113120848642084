import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { api } from '../lib/api';
import { GeocodeFeature, geocode } from '../lib/geocoding';

const QueriedLocationContext = createContext<{
    pending: boolean;
    feature: GeocodeFeature | null;
    region: ISeeChange.Region | null;
}>({
    pending: true,
    feature: null,
    region: null,
});

export default function QueriedLocationProvider({ children }: { children: ReactNode }) {
    const [query] = useSearchParams();
    const [pending, setPending] = useState(true);
    const [feature, setFeature] = useState<GeocodeFeature | null>(null);
    const [region, setRegion] = useState<ISeeChange.Region | null>(null);

    const queryLocation = query.get('home'); // The name of a feature to look up, or...
    const queryRegionLabel = query.get('region'); // ...the label of a region

    useEffect(() => {
        setPending(true);

        const waitFor: Promise<unknown>[] = [];

        // Check only when present; the query won't necessarily persist but the context should.

        if (queryLocation) {
            setFeature(null);
            console.info(`Geocoding queried location name "${queryLocation}"`);
            const waitForGeocode = geocode(queryLocation, false).then(features => {
                const firstWithBbox = features.find(f => f.bbox);
                console.info('Queried location name matched', firstWithBbox);
                if (firstWithBbox) setFeature(firstWithBbox);
            });
            waitFor.push(waitForGeocode);
        }

        if (queryRegionLabel) {
            setRegion(null);
            console.info(`Fetching queried region "${queryRegionLabel}"`);
            const spacedLabel = queryRegionLabel.replace(/_/g, ' ');
            const waitForRegion = api('GET', '/regions', { label: spacedLabel }).then(({ response }) => {
                const labelToMatch = spacedLabel.trim().toLowerCase();
                const match = response.regions.find(r => r.centerGeoPointGeoJson !== null && r.label.trim().toLowerCase() === labelToMatch);
                console.info('Queried region matched', match);
                if (match) setRegion(match);
            });
            waitFor.push(waitForRegion);
        }

        const waitForAndIgnoreErrors = waitFor.map(p => p.catch(e => console.warn(e)));
        Promise.all(waitForAndIgnoreErrors).then(() => {
            setPending(false);
        });
    }, [queryLocation, queryRegionLabel]);

    return (
        <QueriedLocationContext.Provider value={{ pending, feature, region }}>
            {children}
        </QueriedLocationContext.Provider>
    );
}

QueriedLocationProvider.useQueriedLocation = function() {
    return useContext(QueriedLocationContext);
}
