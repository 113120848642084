import { get, set } from 'idb-keyval';
import { useState } from 'react';

const browserLocalStorage = typeof localStorage !== 'undefined' ? localStorage : null;

export const safeLocalStorage = {
    get(key: string) {
        const rawValue = browserLocalStorage?.getItem(key) ?? undefined;
        if (rawValue !== undefined) {
            return JSON.parse(rawValue);
        }
    },

    set(key: string, value: unknown) {
        if (value === undefined) {
            return browserLocalStorage?.removeItem(key);
        } else {
            return browserLocalStorage?.setItem(key, JSON.stringify(value));
        }
    },
};

export function useLocalStorage<T>(key: string) {
    const initialValue = safeLocalStorage.get(key);

    const [value, setValue] = useState<T | undefined>(initialValue);

    function set(value: T | undefined) {
        safeLocalStorage.set(key, value);
        setValue(value);
    }

    return [value, set] as const;
}

export const safeIdb = {
    get(key: string) {
        if (typeof indexedDB !== 'undefined') {
            return get(key).catch(console.error);
        }
    },

    set(key: string, value: unknown) {
        if (typeof indexedDB !== 'undefined') {
            return set(key, removeUnserializableValues(value)).catch(console.error);
        }
    },
};

function removeUnserializableValues(value: unknown, _touched = new Set<unknown>()): unknown {
    if (value instanceof Function) {
        return undefined;
    } else if (value instanceof Promise) {
        return undefined;
    } else if (Array.isArray(value)) {
        if (_touched.has(value)) throw new Error('removeUnserializableValues ran into a circle in an array');
        _touched.add(value);
        const newValue = value.map((v => removeUnserializableValues(v, _touched)));
        _touched.delete(value);
        return newValue;
    } else if (value?.constructor === Object) {
        if (_touched.has(value)) throw new Error('removeUnserializableValues ran into a circle in an object');
        _touched.add(value);
        const newValue = Object.fromEntries(Object.entries(value)
            .map(([key, value]) => [key, removeUnserializableValues(value, _touched)]));
        _touched.delete(value);
        return newValue;
    } else {
        // To determine if a value can be stored, see:
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
        return value;
    }
}
