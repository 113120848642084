import QueriedLocationProvider from './QueriedLocation';
import CurrentStepProvider from './Step';
import SubmissionProvider from './Submission';
import WorkflowProvider from './Workflow';

export function useWorkflow() {
    return WorkflowProvider.useContext();
}

export function useCurrentStep() {
    return CurrentStepProvider.useContext();
}

export function useSubmission() {
    return SubmissionProvider.useContext();
}

export function useQueriedLocation() {
    return QueriedLocationProvider.useQueriedLocation();
}
