import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSubmission, useWorkflow } from '.';
import { useNiceNav } from '../lib/routing';
import { WorkflowStep } from '../workflows';
import { mergeIndividualTranslations, useTranslations } from '../lib/i18n';
import { Helmet } from 'react-helmet-async';

type CurrentStep = WorkflowStep & { key: string, nextStepKey: string | null };

const CurrentStepContext = createContext<CurrentStep | undefined>(undefined);

export default function CurrentStepProvider(props: Omit<React.ComponentProps<typeof CurrentStepContext.Provider>, 'value'>) {
    const params = useParams();
    const [navigate] = useNiceNav();
    const workflow = useWorkflow();
    const submission = useSubmission();

    const [currentStep, setCurrentStep] = useState<CurrentStep>();

    const t = useTranslations(mergeIndividualTranslations({
        workflowTitle: workflow?.pageTitle,
        pageTitle: currentStep?.pageTitle
            ?? currentStep?.breadcrumbLabel
            ?? currentStep?.component.defaultPageTitle
            ?? currentStep?.component.defaultBreadcrumbLabel,
    }));

    useEffect(() => {
        if (!workflow) return;

        if (!params.step) {
            console.info(`Defaulting to first step "${workflow.firstStep}" of workflow "${workflow.key}"`);
            const search = Object.fromEntries(new URLSearchParams(location.search));
            navigate('step', { step: workflow.firstStep }, { search, replace: true });
            return;
        }

        const stepKey = (params.step ?? '') as keyof typeof workflow.stepProgression;
        const currentStep = workflow.stepProgression[stepKey];
        if (!currentStep) throw new Error(`Step "${stepKey}" not found in workflow "${workflow.key}"`);
        const nextStepKey = (submission && currentStep.next?.(submission)) ?? null;
        setCurrentStep({ ...currentStep, key: stepKey, nextStepKey });
    }, [navigate, params.step, submission, workflow]);

    const pageTitle = [t('workflowTitle'), t('pageTitle')].filter(Boolean).join(' - ');

    return <>
        <Helmet>
            <title>{pageTitle}</title>
        </Helmet>

        <CurrentStepContext.Provider {...props} value={currentStep} />
    </>;
}

CurrentStepProvider.useContext = () => useContext(CurrentStepContext);
