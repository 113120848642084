import { useEffect, useState } from 'react';

export function useWidthMediaQuery(compare: '<' | '>', width: string) {
    const query = `(${compare === '<' ? 'max-width' : 'min-width'}: ${width})`;
    const [matches, setMatches] = useState(matchMedia(query).matches);

    useEffect(() => {
        function handleChange(event: MediaQueryListEvent) {
            setMatches(event.matches);
        }

        const matcher = matchMedia(query);
        matcher.addEventListener('change', handleChange);

        return () => {
            matcher.removeEventListener('change', handleChange);
        }
    }, [query]);

    return matches;
}

function getVisualViewportHeight() {
    return globalThis.visualViewport?.height ?? innerHeight;
}

export function useVisualViewportHeight(handler: (height: number, scroll: number) => ReturnType<React.EffectCallback>) {
    const [scroll, setScroll] = useState(document.documentElement.scrollTop);
    const [height, setHeight] = useState(getVisualViewportHeight());

    useEffect(() => {
        async function watchViewport(event?: Event) {
            const start = Date.now();
            const duration = event?.type.startsWith('focus') ? 2000 : 49;
            setScroll(document.documentElement.scrollTop);
            while (Date.now() <= start + duration) {
                setHeight(getVisualViewportHeight());
                await new Promise(r => setTimeout(r, 50));
            }
        }

        watchViewport();

        addEventListener('focusin', watchViewport); // Run when the virtual keyboard opens...
        addEventListener('focusout', watchViewport); // ...and closes...
        addEventListener('resize', watchViewport); // ...or the window is resized another way.

        return () => {
            removeEventListener('focusin', watchViewport);
            removeEventListener('focusout', watchViewport);
            removeEventListener('resize', watchViewport);
        };
    }, []);

    useEffect(() => {
        return handler(height, scroll);
    }, [handler, height, scroll]);

    return height;
}
