import { TranslationsConfig, useTranslations } from 'isc-shared/i18n';

// E.g. { en: 'Hello', es: 'Hola'}
export type IndividualTranslation<Output extends React.ReactNode = React.ReactNode> = { [lang: string]: Output };

export { useTranslations };

export function pullTranslationsFromSources(
    sources: object[],
    path: string[],
    keyPrefix: string,
    mergeInto: TranslationsConfig = {},
) {
    sources.forEach((source, i) => {
        const mutablePath = [...path];
        let translations = source;
        while (mutablePath.length !== 0) {
            translations = translations[mutablePath.shift() as keyof typeof translations];
            if (!translations) return;
        }
        Object.entries(translations).forEach(([lang, result]) => {
            mergeInto[lang] ??= {};
            mergeInto[lang]![`${keyPrefix}${i}`] = result;
        });
    });

    return mergeInto;
}

export function mergeIndividualTranslations<
    IndividualsConfig extends { [key: string]: IndividualTranslation | undefined },
    BaseConfig extends TranslationsConfig,
    IndyKey extends keyof IndividualsConfig,
    BaseLang extends BaseConfig extends BaseConfig ? keyof BaseConfig : never, // https://stackoverflow.com/a/49402091
    BaseKey extends keyof BaseConfig[BaseLang],
    Key extends IndyKey | BaseKey,
    Result extends {
        [lang in BaseLang]: lang extends BaseLang ? {
            [key in Key]: key extends BaseKey ? BaseConfig[lang][key] : key extends IndyKey ? NonNullable<IndividualsConfig[key]>[lang] : never;
        } : never;
    },
>(
    individuals: IndividualsConfig,
    base?: BaseConfig,
) {
    const result: Partial<Result> = {};

    if (base) {
        Object.entries(base).forEach(([lang, keyMap]) => {
            result[lang as BaseLang] = { ...keyMap as Result[BaseLang] };
        });
    }

    Object.entries(individuals).forEach(([key, langMap]) => {
        if (!langMap) return;
        Object.entries(langMap).forEach(([lang, translation]) => {
            result[lang as BaseLang] ??= {} as Result[BaseLang];
            result[lang as BaseLang]![key as keyof Result[BaseLang]] = translation as unknown as Result[BaseLang][keyof Result[BaseLang]];
        });
    });

    return result as Result;
}
