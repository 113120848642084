import env from './env';

declare const dataLayer: { event: string }[];

const IS_PRODUCTION = env('MODE') === 'production';

const oldPropertyKeysToClear: Record<string, undefined> = {};

export default function trackEvent(event: string, properties: object = {}) {
    if (!IS_PRODUCTION) console.info(`Event "${event}"`, properties);
    dataLayer.push({ ...oldPropertyKeysToClear, event, ...properties });

    // Pretty odd, but next time we track an event, we'll clear out the properties from the previous ones.
    // https://www.simoahava.com/gtm-tips/remember-to-flush-unused-data-layer-variables/
    for (const key of Object.keys(properties)) {
        oldPropertyKeysToClear[key] = undefined;
    }
}
