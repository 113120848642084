import Button from 'isc-shared/ui/Button';
import Heading from 'isc-shared/ui/Heading';
import Modal from 'isc-shared/ui/Modal';
import Stack from 'isc-shared/ui/Stack';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrentStep, useSubmission, useWorkflow } from '../../context';
import { useTranslations } from '../../lib/i18n';
import { useNiceNav } from '../../lib/routing';
import Form from '../ui/Form';

const SHOW_STATE = new URL(location.href).searchParams.has('debug');

const strings = {
    en: {
        submissionErrorTitle: 'Submission error',
        submissionErrorBody: 'We’re sorry, but something went wrong! Our team has been notified and will look into it.',
        tryAgain: 'Try again',
        cancel: 'Cancel',
    },

    es: {
        submissionErrorTitle: 'Error de presentación',
        submissionErrorBody: 'Lo sentimos, pero algo ha ido mal. Nuestro equipo ha sido notificado y lo investigará.',
        tryAgain: 'Inténtalo de nuevo',
        cancel: 'Cancelar',
    },

    fr: {
        submissionErrorTitle: 'Erreur de soumission',
        submissionErrorBody: 'Nous sommes désolés, une erreur s\'est produite! Notre équipe a été informée et l\'examinera.',
        tryAgain: 'Essayer à nouveau',
        cancel: 'Annuler',
    },

    ht: {
        submissionErrorTitle: 'Soumèt erè',
        submissionErrorBody: 'Nou regrèt, men yon bagay ale mal! Ekip nou an te avize epi yo pral gade nan li.',
        tryAgain: 'Eseye ankò',
        cancel: 'Anile',
    },
};

export default function SubmissionForm() {
    const [searchParams] = useSearchParams();
    const normalNavigate = useNavigate();
    const [niceNavigate] = useNiceNav();
    const t = useTranslations(strings);

    const workflow = useWorkflow();
    const currentStep = useCurrentStep();
    const submission = useSubmission();

    const [submissionInProgress, setSubmissionInProgress] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const dismissErrors = useCallback((toDismiss: typeof errors) => {
        const set = new Set(errors);
        toDismiss.forEach(e => set.delete(e));
        setErrors(Array.from(set));
    }, [errors]);

    const handleNext = useCallback(async () => {
        if (!currentStep || !submission) return;

        let hadErrors = false;

        if (currentStep.sendSubmission?.(submission)) {
            try {
                setSubmissionInProgress(true);
                await submission.submit();
            } catch (error) {
                console.error(error);
                setErrors(['submission_error']);
                hadErrors = true;
            } finally {
                setSubmissionInProgress(false);
            }
        } else {
            try {
                setErrors([]);
                const exitResult = await currentStep.beforeExit?.(submission);
                if (exitResult?.errors) {
                    setErrors(exitResult.errors);
                    hadErrors = true;
                }
            } catch (error) {
                setErrors(['unexpected']);
                hadErrors = true;
                alert(error); // TODO: Display inline.
            }
        }

        if (!hadErrors) {
            const nextFromUrl = searchParams.get('next');
            if (nextFromUrl) {
                normalNavigate(nextFromUrl);
            } else {
                const nextStep = await currentStep.next?.(submission);
                if (nextStep) {
                    niceNavigate('step', { step: nextStep });
                } else {
                    setErrors(['no_next_step']);
                }
            }
        }
    }, [currentStep, submission, searchParams, normalNavigate, niceNavigate]);

    const StepComponent = currentStep?.component;
    const stepProps = useMemo(() => {
        if (!workflow || !currentStep || !submission) return;
        return currentStep.getProps?.(submission, workflow, currentStep) ?? {};
    }, [currentStep, submission, workflow]);

    if (!currentStep || !submission || !StepComponent) return null;

    return <>
        {errors.includes('submission_error') && (
            <Modal title={t('submissionErrorTitle')} style={{ width: '30rem' }} onDismiss={() => dismissErrors(['submission_error'])}>
                <Form onSubmit={() => { dismissErrors(['submission_error']); handleNext(); }}>
                    <Heading.Level>
                        <Stack>
                            <Heading like={3}>{t('submissionErrorTitle')}</Heading>
                            <p>{t('submissionErrorBody')}</p>
                            <Button type="submit" level="primary" block>{t('tryAgain')}</Button>
                            <Button level="secondary" block onClick={() => dismissErrors(['submission_error'])}>{t('cancel')}</Button>
                        </Stack>
                    </Heading.Level>
                </Form>
            </Modal>
        )}

        <Form style={{ display: 'contents' }} onSubmit={handleNext}>
            <StepComponent
                errors={errors}
                value={submission.values[currentStep.key as keyof typeof submission.values]}
                submissionInProgress={submissionInProgress}
                dismissErrors={dismissErrors}
                onChange={(value: unknown) => submission.update({ [currentStep.key]: value })}
                {...stepProps}
            />
        </Form>

        {SHOW_STATE && (
            <div style={{ background: '#fff8', backdropFilter: 'blur(0.5rem)', bottom: 0, left: 0, maxHeight: 'min(30ch, 80vh)', maxWidth: '90vw', overflow: 'auto', padding: '0.5rem', position: 'fixed', zIndex: 1 }}>
                <details>
                    <summary>{'{···}'}</summary>
                    <pre>{JSON.stringify(submission, null, 2)}</pre>
                </details>
            </div>
        )}
    </>;
}
